<!--
 * @Description: 软件商品管理
 * @Author: ChenXueLin
 * @Date: 2021-09-26 14:59:30
 * @LastEditTime: 2022-07-22 14:03:19
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="firstClassId">
              <e6-vr-select
                v-model="searchForm.firstClassId"
                :loading="firstClassLoading"
                :data="firstClassEnum"
                placeholder="商品分类"
                title="商品分类"
                @change="firstClassChange"
                @clear="firstClassChange"
                :props="{
                  id: 'firstClassId',
                  label: 'firstClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="secondClassId">
              <e6-vr-select
                v-model="searchForm.secondClassId"
                :data="secondClassEnum"
                :loading="secondClassIdLoading"
                @change="secondClassChange"
                @clear="secondClassChange"
                placeholder="商品开票名称"
                title="商品开票名称"
                :props="{
                  id: 'secondClassId',
                  label: 'secondClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdClassId">
              <e6-vr-select
                v-model="searchForm.thirdClassId"
                :data="thirdClassEnum"
                :loading="thirdClassLoading"
                placeholder="商品名称"
                title="商品名称"
                :props="{
                  id: 'thirdClassId',
                  label: 'thirdClassName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isEnable">
              <e6-vr-select
                v-model="searchForm.isEnable"
                :data="usedStateEnum"
                placeholder="商品可用状态"
                title="商品可用状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createdByName">
              <el-input
                v-model.trim="searchForm.createdByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="remark">
              <el-input
                v-model="searchForm.remark"
                placeholder="备注"
                title="备注"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="shelfStatus">
              <e6-vr-select
                v-model="searchForm.shelfStatus"
                :data="shelfStatusEnum"
                placeholder="是否上架"
                title="是否上架"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i class="e6-icon-export_fill" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          @sort-change="sortChange"
          highlight-current-row
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :sortable="column.sortable"
            :align="column.align"
            header-align="center"
          >
            <!-- <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'thirdClassName'">
                <el-button type="text" @click="handleOperate({ id: 1 }, row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template> -->
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import goodsClassInteractions from "@/mixins/commomFormInteractions/goodsClassInteractions";
import { getSoftwareGoods, exportSoftwareGoods, findDownList } from "@/api";
import { printError } from "@/utils/util";
import { exportExcelByUrl, exportMessageHandle } from "@/utils/download";
export default {
  name: "SoftWareList",
  mixins: [listPage, listPageReszie, base, goodsClassInteractions],
  data() {
    return {
      queryListAPI: getSoftwareGoods,
      searchForm: {
        thirdClassId: "", //商品名称
        secondClassId: "", //商品开票名称
        firstClassId: "", //商品分类
        isEnable: "", //商品可用状态
        remark: "", //备注
        createdByName: "", //创建人
        createTime: [],
        createdTimeStrat: "", //创建时间起
        createdTimeEnd: "", //创建时间止
        shelfStatus: "", // 上架状态
        pageIndex: 1,
        pageSize: 20,
        sortDir: "",
        sortIndex: ""
      },
      shelfStatusEnum: [], // 上架状态枚举
      usedStateEnum: [], // 商品可用状态枚举
      total: 0,
      columnData: [
        {
          fieldName: "thirdClassId",
          display: true,
          fieldLabel: "商品ID",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "typeId",
          display: true,
          fieldLabel: "商品类型",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "isEnable",
          display: true,
          fieldLabel: "商品可用状态",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "createdByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: "custom",
          align: "center"
        },
        {
          fieldName: "updatedByName",
          display: true,
          fieldLabel: "修改人",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          sortable: false,
          align: "center"
        }
      ], //表头
      tableData: [{ updatedTime: "2021-02" }] // 表格数据
    };
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [findDownList(["shelfStatus", "usedState"])];
        let [findDownListRes] = await Promise.all(promiseList);
        this.shelfStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.shelfStatus"
        });
        this.usedStateEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.usedState"
        });
      } catch (error) {
        printError(error);
      }
    },
    //点击历史记录
    toRecord() {
      this.$router.push({
        name: "softwareProductsRecord",
        params: {
          refresh: true
        },
        query: {}
      });
    },
    // 导出
    async exportList() {
      try {
        this.loading = true;
        let res = await exportSoftwareGoods(this.searchForm);
        let url = this.getFreezeResponse(res, {
          path: "data",
          defaultVal: ""
        });
        if (url) {
          exportExcelByUrl(url);
        } else {
          exportMessageHandle(this);
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 导出
    handleExport() {
      this.exportList();
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row = {}) {
      if (val.id == 1) {
        this.routerPush({
          name: "goodsManage/addSoftWare",
          params: {
            refresh: true,
            origin: this.$route.name,
            id: row.thirdClassId
          }
        });
      }
    },
    handleAdd() {
      this.routerPush({
        name: "goodsManage/addGood",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
